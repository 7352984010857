<template>
  <BaseButton type="primary" @click="handleClick">
    <template slot="icon">
      <span class="svg-icon svg-icon-white svg-icon-sm">
        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <rect height="24" width="24" x="0" y="0"/>
            <circle cx="9" cy="15" fill="#000000" r="6"/>
            <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3"/>
          </g>
        </svg>
      </span>
    </template>
    New Record
  </BaseButton>
</template>

<script>
import BaseButton from '@/component-v2/BaseButton'

export default {
  components: {
    BaseButton
  },
  methods: {
    handleClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>
