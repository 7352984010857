<template>
  <BaseContainer>
    <BaseCard subtitle="List of Projects" title="Project List">
      <template #toolbar>
        <NewButton @click="createProject" />
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search"
          placeholder="Search by name or company code or code" v-model="search"></el-input>
        <span class="f-13 mr-2">Status :</span>
        <el-select size="medium" class="mr-4" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="0" label="Draft"></el-option>
          <el-option :value="1" label="Draft"></el-option>
          <el-option :value="2" label="Active"></el-option>
          <el-option :value="3" label="Finalized"></el-option>
          <el-option :value="4" label="Fulfilled"></el-option>
          <el-option :value="5" label="Void"></el-option>
          <el-option :value="6" label="Deleted"></el-option>
        </el-select>
      </template>
      <BaseTable :data="projects" @row-click="readProject" v-loading="loading">
        <el-table-column label="Name" min-width="150" prop="name" />
        <el-table-column label="Code" min-width="150" prop="code" />
        <el-table-column label="Company" min-width="150" prop="company.name" />
        <el-table-column label="Status" min-width="100">
          <template slot-scope="slot">
            <gf-label inline pill light type="success" v-if="!slot.row.status">Active</gf-label>
          </template>
        </el-table-column>
        <el-table-column label="Created At" min-width="150" prop="created_at">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Last Updated" min-width="150" prop="updated_at">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </BaseTable>
      <template #footer>
        <BasePagination :pagination="pagination" @change="listProjects" />
      </template>
    </BaseCard>
  </BaseContainer>
</template>

<script>
import BaseContainer from '@/component-v2/BaseContainer'
import BaseCard from '@/component-v2/BaseCard'
import BaseTable from '@/component-v2/BaseTable'
import BasePagination from '@/component-v2/BasePagination.vue'
import NewButton from '@/component-v2/NewButton'
import ProjectService from '@/services/v1/ProjectService'

export default {
  components: {
    NewButton,
    BaseCard,
    BaseContainer,
    BaseTable,
    BasePagination
  },
  data() {
    return {
      openFormProjectModal: false,
      formProjectModalMode: 'create',

      loading: false,
      projects: [],

      // pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  methods: {
    createProject() {
      this.$router.push({ name: 'project-create' })
    },
    readProject(row) {
      this.$router.push({ name: 'project-view', params: { id: row.id } })
    },

    async listProjects() {
      try {
        this.loading = true
        const projectService = new ProjectService()
        const response = await projectService.list(
          this.pagination.page,
          null,
          this.filter,
          null
        )
        this.projects = response.data.rows

        if (response.data.count === 0 && response.data.rows.length !== 0) {
        } else this.pagination.total = response.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Project', route: '' },
      { title: 'List' }
    ])

    await this.listProjects()
  }
}
</script>
